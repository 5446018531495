<template>

  <div>
    <!-- Início filtro -->
    <v-dialog
      v-model="modal.filtro"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-app-bar-nav-icon @click="modal.filtro = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
          <v-icon class="ml-2"> fas fa-user </v-icon>
          <v-toolbar-title class="pl-4"> Filtrar Logs </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="limparFiltros">
            <v-icon>fas fa-broom</v-icon>
          </v-app-bar-nav-icon>
          <v-app-bar-nav-icon @click="carregarLogs(), (modal.filtro = false)">
            <v-icon>fas fa-search</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-col class="pt-6">
            <v-form>
              <v-row>
                <v-select
                  dense
                  label="Filtrar por "
                  :items="itensFiltro"
                  prepend-icon="fas fa-search"
                  v-model="filtros.codigo"
                  item-text="name"
                  item-value="id"
                  outline
                ></v-select>
              </v-row>
            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim filtro -->
    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-user </v-icon>
        <v-toolbar-title class="pl-4"> Logs Crons {{this.headerlog}} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="modal.filtro = true">
          <v-icon>fas fa-search</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click="$emit('principal')">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>

        <v-row>
          <v-col cols="2" class="alinhamento">
            <v-dialog
              ref="modal_data_inicio"
              v-model="modal.data_inicio"
              :return-value.sync="dataInicio"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  prepend-icon="fas fa-calendar-alt"
                  label="Data inicial"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formatDateInicioGrid"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="dataInicio" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.data_inicio = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_data_inicio.save(dataInicio)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col cols="2" class="alinhamento">
            <v-dialog
              ref="modal_data_final"
              v-model="modal.data_final"
              :return-value.sync="dataFinal"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  label="Data final"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formatDateFinalGrid"
                  clearable
                  append-outer-icon="fas fa-search"
                  @click:append-outer="filtroLogs()"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dataFinal" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.data_final = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_data_final.save(dataFinal)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
              dense
              :headers="grid.logs.cabecalho"
              :items="grid.logs.items"
              :items-per-page="grid.logs.porPagina"
              hide-default-footer
              class="elevation-1 pt-4"
              :loading="grid.logs.carregando"
              >
              <!-- This template looks for headers with formatters and executes them -->
              <template
                v-for="header in grid.logs.cabecalho.filter((header) =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
              >
                {{ header.formatter(value) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col cols="2">
            <v-btn
              fab
              small
              color="primary"
              dark
              @click="voltarTopo()"
              style="margin-left: 10px"
            >
              <v-icon> fas fa-arrow-up </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8">
            <v-pagination
              v-model="grid.logs.paginaAtual"
              :length=" Math.ceil(grid.logs.totalRegistros / this.grid.logs.porPagina) "
              @input="mudarPagina"
              :total-visible="5"
            ></v-pagination>
          </v-col>
          <v-col cols="2"> </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>

</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import moment from "moment";
import Template from "../Template.vue";

export default {
  name: "LogsCron",
  components: {
    Template,
  },
  props: {
    statuscron: { type: Object },
    // logmodal: { type: Boolean },
  },
  // model: {
  //     prop: 'logmodal',
  //     event: 'close',
  // },

  data: () => ({
    dataInicio: moment(new Date()).format("YYYY-MM-DD"),
    dataFinal: moment(new Date()).format("YYYY-MM-DD"),

    grid: {
      logs: {
        cabecalho: [
          { text: "#", value: "codigo", sortable: false },
          { text: "Descrição", value: "descricao", sortable: false },
          { text: "Data", value: "executado_em", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        porPagina: 100,
        qtdsPaginacao: [10, 15, 50, 100],
        itemSelecionado: [],
        coluna: "codigo",
        direcao: "desc",
      },
    },
    modal: {
      filtro: false,
      data_inicio: false,
      data_final: false,
    },
    filtros: {
      codigo: "",
      data_inicio: null,
      data_final: null,
    },
    itensFiltro: [],
    headerlog: "",
  }),
  async mounted() {
    this.headerlog = this.statuscron.descricao;
    await this.carregarLogs( this.statuscron );
  },
  computed: {
    formatDateInicioGrid() {
      return this.dataInicio ? moment(this.dataInicio).format("DD/MM/YYYY") : "";
    },
    formatDateFinalGrid() {
      return this.dataFinal ? moment(this.dataFinal).format("DD/MM/YYYY") : "";
    },
  },
  methods: {
    limparFiltros() {
      this.filtros.codigo = "";
    },
    fechar() {
      this.$router.push("/");
    },
    async filtroLogs() {
      await this.carregarLogs( this.statuscron );
    },
    async carregarLogs(item) {

      if (Number(item.idstatus_cron) > 0)
        this.filtros.codigo = item.idstatus_cron

      this.grid.logs.carregando = true;
      let url = `${baseApiUrl}/logs-crons`;
      url += `?page=${this.grid.logs.paginaAtual}`;
      url += `&coluna=${this.grid.logs.coluna}`;
      url += `&direcao=${this.grid.logs.direcao}`;
      url += `&por_pagina=${this.grid.logs.porPagina}`;
      // url += `&fim=${moment(this.dataFinal).format("YYYY-MM-DD")}&inicio=${moment(this.dataInicio).format("YYYY-MM-DD")}`;

      url += `&filter=${this.filtros.codigo}`;

      await axios
        .get(url)
        .then((res) => {
          if (res.data.retorno) {
            this.grid.logs.items = res.data.dados.data;
            this.itensFiltro = res.data.filter;
            this.grid.logs.totalRegistros = res.data.dados.total;
            this.grid.logs.porPagina = Number(res.data.dados.per_page);
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => (this.grid.logs.carregando = false));
    },
    mudarPagina(pagina) {
      this.grid.logs.paginaAtual = pagina;
      this.carregarLogs(this.statuscron);
    },
  },
  watch: {
    "grid.logs.porPagina": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val > 0) {
            this.grid.logs.porPagina = Number(val);
            this.mudarPagina(1);
          } else {
            this.grid.logs.porPagina = 100;
            this.mudarPagina(1);
          }
        }
      },
    },
  },
}
</script>

<style>

</style>